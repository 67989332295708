<template>
  <v-dialog
    v-if="help != true"
    :width="this.size != null ? size : '400'"
    v-model="dialog"
  >
    <template v-slot:activator="{ on }">
      <v-btn elevation="0" icon @click="open" v-on="on" style="margin-top: 5px">
        <v-icon v-if="small" small>mdi-plus-circle</v-icon>
        <v-icon v-else size="40">mdi-plus-circle</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ $t("routes.brandCreate") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false">
          <v-icon id="iconClose">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text class="pb-2 " style="padding-top: 0 !important">
        <brands-form
          :title="$t('new', { name: $tc('brand') })"
          :brand="brand"
          @validated="saveBrand()"
          @back="dialog = false"
        ></brands-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import Btn from "../pages/components/Btn.vue";
//import constants from "@/constants";
export default {
  name: "BrandNew",
  props: ["small"],
  components: { BrandsForm: () => import("@/components/brand/Form") },
  data() {
    return {
      brand: {},
      dialog: false,
    };
  },
  methods: {
    ...mapActions("brand", ["addBrand"]),
    saveBrand() {
      this.addBrand({ brand: this.brand }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("brand_create_success"), "", "");
          this.dialog = false;
          this.$emit("save");
        }
      });
    },
    open() {
      this.brand = {};
    },
  },
};
</script>
